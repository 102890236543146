import $ from 'jquery';
import 'what-input';
// import Swiper from 'swiper';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
// require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';

$(document).foundation();


//alerts
if (document.body.contains(document.querySelector('.alert-container'))) {
const alert = document.querySelector('.alert-container');
 setTimeout(() => {
       alert.style.display = "none";                        
}, 4000);
}


//Home slider
if (document.body.contains(document.querySelector('.testimonial-container'))) {
    const swiperTestimonial = new Swiper(".testimonial-container", {
        loop: true,
        autoplay: {
        delay: 3000,
        disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true
        },
    });
}


// confirm checked/unchecked 1
if (document.body.contains(document.querySelector('.google-ads'))) {
  const checkBox = document.querySelector('.confirmed');
  const btnVerify = document.querySelector(".verified");
  checkBox.addEventListener("click", function(){
      if (checkBox.checked === true){
          btnVerify.classList.remove("disable-btn");
      } else {
          btnVerify.classList.add("disable-btn");
      }
  });
}

// confirm checked/unchecked telephone, radio and checkbox
if (document.body.contains(document.querySelector('.merchant-center'))) {
    const checkBox = document.querySelector('.confirmed'); 
    const btnVerify = document.querySelector(".verified");
    const radios = document.querySelectorAll('input[type="radio"]');
    const phone = document.querySelector('input[name="phone"]');

phone.addEventListener("keypress", function() {        
    if(phone.value !== ""  && radio.checked === true){ 
        btnVerify.classList.remove("disable-btn");           
    }else if(radio.checked !== false && phone.value !== ""){ 
        btnVerify.classList.remove("disable-btn");   
    }else{ 
        btnVerify.classList.add("disable-btn"); 
    }
}); 

radios.forEach(radio => {
        radio.addEventListener('click', () => {
        if (radio.checked !== false && phone.value !== ""){
            btnVerify.classList.remove("disable-btn");  
        } else{ 
            btnVerify.classList.add("disable-btn");   
        }
    });
  }); 
checkBox.addEventListener("click", function(){
      if(checkBox.checked === false){ 
            btnVerify.classList.add("disable-btn");           
                                        
        }else{ 
             btnVerify.classList.remove("disable-btn"); 
        }
    }); 
}


// to check all six checkboxes in order to showing button

if (document.body.contains(document.querySelector('.all-accounts'))) {   
const resumeBtn = document.querySelector('.verified');  
const checkBox = document.getElementsByTagName("input");


for (let i = 0; i < checkBox.length; i++) {
    checkBox[i].addEventListener('click', function(){         

        if(document.querySelectorAll('input[type="checkbox"]:checked').length === checkBox.length){
             resumeBtn.classList.remove("disable-btn");
        }else{
             resumeBtn.classList.add("disable-btn");
        }
    });
  }
}

// change button color  by typing
if (document.body.contains(document.querySelector('#btn-phone'))) {  
    const textInput = document.querySelector('#my-code');
    const codeReceived = document.querySelector('#code-received');
    const button = document.querySelector('#btn-phone');

    textInput.addEventListener("keyup", function() {
    if(textInput.value === ""){ 
        button.classList.add('disable-btn');
        button.classList.remove('enable-btn');
        codeReceived.style.backgroundColor = '#008FFB';           
        
    }else{   
        button.classList.remove('disable-btn');
        button.classList.add('enable-btn');       
        codeReceived.style.backgroundColor = '#F4F5F7';         
                
    }
   
 });

}

//Range slider in Campaign Page
if (document.body.contains(document.querySelector('.campaign'))) {  
    const slider = document.querySelector('.slider');
    const numValue = document.querySelector('#num-value');

    slider.addEventListener('input', function(){
        numValue.value = slider.value;
    });
    numValue.addEventListener('input', function(){
        slider.value = numValue.value;
});
}

